<template>
  <div class="page">
    <div class="container-info card">
      <div class="font-weight-bold text-primary">Título del Evento</div>
      <div class="text-m mb-0 text-gray" v-if="eventS.event_stream_id !== null">{{ eventS.title }}</div>
      <input v-model="description" v-if="eventS.event_stream_id === null" />
      <div class="font-weight-bold text-primary">Descripción del Evento</div>
      <div class="text-m mb-0 text-gray" v-if="eventS.event_stream_id !== null">{{ eventS.description }}</div>
      <input v-model="title" v-if="eventS.event_stream_id === null"/>
      <div class="font-weight-bold text-primary">Plataformas del Evento</div>
      <div class="text-m mb-0 text-gray" v-if="eventS.event_stream_id !== null">
        {{ eventS.platform }}
      </div>
      <input v-model="platform" v-if="eventS.event_stream_id === null"/>
      <div class="create-btn" v-if="eventS.event_stream_id === null">
        <button class="sc-btn"><span>Crear Stream</span></button>
      </div>
    </div>

    <div class="container-stream card">
      <div class="font-weight-bold text-success">Servidor RTMPS</div>
      <div class="text-m mb-0 text-gray">
        <div v-if="eventS.event_stream_id === null">No generado</div>
        <div v-if="eventS.event_stream_id !== null">
          {{ eventS.stream_server }}
        </div>
      </div>
      <div class="font-weight-bold text-success">Stream Key</div>
      <div class="text-m mb-0 text-gray">
        <div v-if="eventS.event_stream_id === null">No generado</div>
        <div v-if="eventS.event_stream_id !== null">
          {{ eventS.stream_key }}
        </div>
      </div>
    </div>

    <div class="container-widgets card">
        <h6 class="m-0 font-weight-bold text-primary">Elementos HTML</h6>
        <div class="font-weight-bold text-primary text-uppercase">
          Reproductor de Video
        </div>
          <input
            readonly
            value='&lt;iframe src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2F105312940857644%2Fvideos%2F149743407298891%2F&amp;show_text=false&amp;width=560&amp;t=0" width="754" height="422" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"&gt;&lt;/iframe&gt;'
          />
        <div class="font-weight-bold text-primary text-uppercase mt-2">
          Chat en Vivo
        </div>
          <input
            readonly
            value='&lt;iframe src="https://cdn.sportscam.pe/widgets/live-chat?height=314&amp;width=560&amp; width="754" height="422" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"&gt;&lt;/iframe&gt;'
          />

        <div class="font-weight-bold text-primary text-uppercase mt-2">
          Contenido Dinámico
        </div>
          <input
            readonly
            value='&lt;iframe src="https://cdn.sportscam.pe/widgets/live-content?height=314&amp;width=560&amp; width="754" height="422" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"&gt;&lt;/iframe&gt;'
          />
    </div>

    <div class="container-tools card">
      <h6 class="m-0 font-weight-bold text-primary">Herramientas</h6>
      <button class="sc-btn" @click="chatModerator">
        <span>Moderador de Chat</span>
      </button>
      <button class="sc-btn" @click="contentAdmin">
        <span>Gestión de Contenido</span>
      </button>
      <button class="sc-btn" @click="overlay"><span>Overlay</span></button>
      <button class="sc-btn" @click="script"><span>Generar Guión</span></button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      eventS: {},
      title: "",
      description: "",
      platform: "",
    };
  },

  mounted() {
    this.loadpage();
  },

  methods: {
    async loadpage() {
      //${process.env.VUE_APP_LIVE_COMMERCE_API}
      await fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/stream/preview`
      )
        .then((response) => response.json())
        .then((data) => (this.eventS = data));

      this.title = this.eventS.event_name
      this.description = this.eventS.event_description
      this.platform = "Facebook"

      if (this.eventS.event_stream_id !== null){
        fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/stream`
        )
        .then((response) => response.json())
        .then((data) => (this.eventS = data));
      }
    },

    create() {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/stream`;
      const body = {
        platform: this.platform,
        title: this.eventS.event_name,
        description: this.eventS.event_description,
      };

      axios
        .post(url, body)
        .then((res) => {
          console.log(res);
          this.loadpage();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    contentAdmin() {
      this.$router.push("/content/" + this.$route.params.event_id);
    },

    script(){
      this.$router.push("/script/" + this.$route.params.event_id);
    }
  },
};
</script>

<style scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.page {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 10px;
  gap: 20px;
  margin-top: 70px;
}

.container-info {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  display: flex;
  flex-direction: column;
  height: 250px;
  align-items: center;
  justify-content: center;
}

.container-stream {
  grid-row: 1 / 1;
  grid-column: 2 / 2;
  display: flex;
  flex-direction: column;
  height: 250px;
  align-items: center;
  justify-content: center;
}

.container-widgets {
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: center;
  justify-content: center;
}

.container-tools {
  grid-row: 2 / 2;
  grid-column: 2 / 2;
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.create-btn{
  margin-top: 10px
}
</style>