<template>
  <div class="page">
    <h3>Configuración</h3>
    <table class="sc-table">
      <thead>
        <tr>
          <th>Usuario</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.email }}</td>
          <td>{{ user.status === '1' ? 'Activo' : 'Inactivo' }}</td>
          <td>
            <!-- <button class="sc-btn"><span>Editar</span></button> -->
            Editar
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.users = [
        { id: 1, email: "rsaettone91@gmail.com", status: '1' },
        { id: 2, email: "gonzalo.garma@sportscam.pe", status: '0'},
      ];
    },
  },
};
</script>

<style scoped>
.page {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 55px;
}

table,
th,
td {
  border: 1px solid;
}
</style>