<template>
  <nav>
    <h3>Live Commerce Platform</h3>
    <div class="content">
      <div class="userInfo">
        <span @click="showMenu">{{ email }}</span>
        <img
          class="img-profile rounded-circle"
          src="@/img/undraw_profile.svg"
        />
        <div class="drop-down-menu" v-show="show">
          <hr class="sidebar-divider" data-v-3eca7188="">
          <ul>
          <li><a href="#">Información</a></li>
          <li><a href="#">Configuración</a></li>
        </ul>
        </div>
      </div>
      <div class="logout">
        <span
          class="mr-2 d-none d-lg-inline text-gray-600 small"
          @click="logout"
          >Logout</span
        >
      </div>
    </div>
  </nav>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      email: "",
      show: false,
    };
  },

  mounted() {
    this.getName();
  },

  methods: {
    getName() {
      if (sessionStorage.getItem("FirebaseToken")) {
        const token = sessionStorage.getItem("FirebaseToken");
        var decoded = VueJwtDecode.decode(token);
        this.email = decoded.email;
      }
    },
    logout() {
      sessionStorage.removeItem("FirebaseToken");
      this.$router.push("/login");
    },
    showMenu(){
      if(this.show){
        this.show = false
      }else{
        this.show = true
      }
    }
  },
};
</script>
<style scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

nav {
  height: 4.375rem;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  padding-left: 20px;
  padding-right: 20px;
  list-style: none;
  color: rgb(133, 135, 150);
  background-color: white;
  position: fixed;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.img-profile {
  height: 2rem;
  width: 2rem;
}

.logout span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.userInfo span:hover{
  text-decoration: underline;
  cursor: pointer;
}

.drop-down-menu{
  display: block;
  position: absolute;
  top: 70px;
  right: 120px;
  width: 14%;
  border: 2rem;
}
ul{
  list-style:none;
  background-color: white;
  margin: 2px;
}
hr.sidebar-divider{
  color: black;
  width: 100%;
  margin: 0 0 0;
}
</style>