<template>
  <div class="page">
    <div class="container-data">
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Lista de Canales</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered" id="dataTable">
              <caption></caption>
              <thead>
                <tr>
                  <th>Nombre del Canal</th>
                  <th>Propietario</th>
                  <th>Correo electrónico</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="live in lives" :key="live">
                  <td>
                    <router-link :to="'/channels/'+live.channel">{{ live.channel }}</router-link>
                  </td>
                  <td>{{ live.name }}</td>
                  <td>{{ live.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lives: [],
    };
  },
  mounted() {
    this.loadChannels();
  },
  methods: {
    loadChannels() {
      fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/channel/`
      )
        .then((response) => response.json())
        .then((data) => (this.lives = data));
    },
  },
};
</script>

<style scoped>
.page {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 55px;
}

.container-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
</style>