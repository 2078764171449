<template>
<div class="page">
  <div class="container-graphics">
    <div class="card">
    <h3>Engagement</h3>
    <Bar
      :chart-options="engagementChartOptions"
      :chart-data="engagementChartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    </div>
    <div class="card">
    <h3>Video Views</h3>
    
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    </div>
     <div class="card">
    <h3>Web Traffic</h3>
    
    <Bar
      :chart-options="trafficChartOptions"
      :chart-data="trafficChartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    </div>
  </div>
  <div class="container-table">
    <div class="card">
      <h3>Transmisiones</h3>
      <table class="table">
        <caption></caption>
        <thead>
          <tr>
            <th># Live</th>
            <th>Fecha</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Video Views</th>
            <th>Likes</th>
            <th>Shares</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vid in videos" :key="vid.id">
            <td>Live #{{ vid.number }}</td>
            <td>{{ vid.video_date }}</td>
            <td>{{ vid.title }}</td>
            <td>{{ vid.description }}</td>
            <td>{{ vid.total_video_views }}</td>
            <td>{{ vid.total_video_like }}</td>
            <td>{{ vid.total_video_share }}</td>
            <td>{{ vid.total_video_comment }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
 
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      videos: [],
      showModal: false,
      chartData: {
        labels: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio' ],
        datasets: [ { label: "views", data: [0, 170686, 243772, 233820, 237120, 31003], backgroundColor: [ '#1975a6' ]} ]

      },
      chartOptions: {
        responsive: true
      },
      engagementChartData: {
        labels: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio' ],
        datasets: [ { label: "shares", data: [0, 80, 154, 183, 114, 19], backgroundColor: [ '#1975a6' ]},
        { label: "likes", data: [0, 1576, 3013, 2958, 2533, 336], backgroundColor: [ '#1975a6' ]},
        { label: "comments", data: [0, 316, 659, 1100, 554, 51], backgroundColor: [ '#1975a6' ]} ]
      },
      engagementChartOptions: {
        responsive: true
      },
      trafficChartData: {
        labels: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio' ],
        datasets: [ { label: "users", data: [0, 104, 106, 119, 135, 184], backgroundColor: [ '#1975a6' ]}]
      },
      trafficChartOptions: {
        responsive: true
      },
    }
  },
  mounted() {
    this.getVideos();
  },
  methods: {
    getVideos() {
          fetch(
              `${process.env.VUE_APP_LIVE_COMMERCE_API}/videos`
            )
              .then((response) => response.json())
              .then((data) => (this.videos = data));
    }
  }
}
</script>

<style scoped>
.page {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  margin-top: 55px;
}

.container-graphics {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

tbody tr:hover {
  background: rgb(240, 240, 240);
}

</style>

