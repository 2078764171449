<template>
  <div class="container background">
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/SC_Logo_White.png" />
      </div>
      <form @submit.prevent="login">
          <input
            class="sc-input"
            v-model="email"
            type="email"
            placeholder="Email"
            autocomplete="username"
            required
          />
          <input
            class="sc-input"
            v-model="password"
            type="password"
            placeholder="Password"
            autocomplete="current-password"
            required
          />
        <div class="form-error" v-if="error">
          {{ error }}
        </div>
          <button class="sc-btn"><span>Sign In</span></button>
      </form>
      <!-- <router-link class="register" :to="'/registration'">Create an Account</router-link> -->
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      const body = {
        email: this.email,
        password: this.password,
      };

      //${process.env.VUE_APP_LOGIN_API}
      const url = `${process.env.VUE_APP_LOGIN_API}/login`;
      axios
        .post(url, body)
        .then((res) => {
          sessionStorage.setItem(
            "FirebaseToken",
            JSON.stringify(res.data.token).slice(1, -1)
          );
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.error = "Invalid email or password.";
          } else {
            this.error = "Please try again later.";
          }
        });
    },
  },
};
</script>

<style scoped>
  .background {
    background: url("@/assets/SC-Live-Commerce-Background.png");
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
  }


.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 400px;
  height: 500px;
  background-color: #1975a6;
  background-image: linear-gradient(180deg, #1975a6 10%, #092a3c 100%);
  background-size: cover;
  border-radius: 20px;
}

form {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

form input {
  text-align: center;
}
.form-error {
  color: white;
  font-size: 10px;
  margin: 5px;
}

.logo img {
  width: 350px;
}

.register{
   color: azure;
    margin-top: 10px;
}
</style>
