<template>
  <div class="page">
    <div class="headers">
      <div id="wrapper">
        <div class="container-fluid">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800">Subir imágenes</h1>
          </div>
          <div class="card mb-4">
            <!-- Card Header - Dropdown -->
            <div
              class="
                card-header
                py-3
                d-flex
                flex-row
                align-items-center
                justify-content-between
              "
            >
              <h6 class="m-0 font-weight-bold text-primary">Subir Imágenes</h6>
            </div>
            <!-- Card Body -->
            <div class="card-body">
              <!-- Nombre del Contenido -->
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-default"
                    >URL 1</span
                  >
                </div>
                <input
                  type="file"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  ref="file"
                  @change="uploadFile($event,1)"
                  required
                />
              </div>
              <!-- Nombre del Contenido -->
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-default"
                    >URL 2</span
                  >
                </div>
                <input
                  type="file"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  @change="uploadFile($event,2)"
                  required
                />
              </div>
              <!-- Nombre del Contenido -->
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-default"
                    >URL 3</span
                  >
                </div>
                <input
                  type="file"
                  class="form-control"
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  @change="uploadFile($event,3)"
                  required
                />
              </div>
            </div>
            <div>
                <button
                class="sc-btn"
                @click="submit"
                >
        <span>Subir</span>
      </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    
    methods:{

        uploadFile(event, order){
            
            console.log("event:", event)

            const selectedFile = event.target.files[0]
            
            const fs = new FormData
            fs.append('myFile', selectedFile, selectedFile.name)
            
            console.log(fs)

            const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/fileUpload/${this.$route.params.event_id}/${this.$route.params.content_id}/${order}`
            
            axios
            .post(url, fs)
            .then((res) => {
                console.log(res);
            });
        },

        submit(){

            this.$router.push("/content/"+this.$route.params.event_id)
        }
    }
}
</script>