<template>
  <div class="page">
    <div class="the-modal">
      <the-modal :show="showModal" @close="showModal = false">
        <template #header>
          <div
            class="
              card-header
              py-3
              d-flex
              flex-row
              align-items-center
              justify-content-between
            "
          >
            <h6 class="m-0 font-weight-bold text-primary">Crear Evento</h6>
          </div> 
        </template>
        <template #body>
          <div class="card mb-4">
              <!-- Card Body -->
              <div class="card-body">
                <!-- Nombre del Contenido -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Nombre del evento</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="event_name"
                    required
                  />
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Descripción del evento</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="event_description"
                    required
                  />
                </div>
              </div>
            </div> 
        </template>
        <template #footer>
            <button
             class="sc-btn"
             @click="create"
             >
        <span>Crear Transmisión</span>
      </button> 
        </template>
      </the-modal>
    </div>
    <div class="container-controls">
      <button
        class="sc-btn"
        @click="showModal = true"
      >
        <span>Crear Transmisión</span>
      </button>
      <button
        class="sc-btn"
        @click="downloadEvents"
      >
        <span>Descargar Eventos</span>
      </button>
    </div>
    <div class="container-data">
      <!-- DataTales Example -->
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            Lista de Transmisiones
          </h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="dataTable"
            >
            <caption></caption>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="eve in events" :key="eve">
                  <td>
                    <router-link :to="'/event/' + eve.event_id">{{
                      eve.event_name
                    }}</router-link>
                  </td>
                  <td>
                    {{ eve.event_description }}
                  </td>
                  <td>{{ eve.event_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import exportXlsFile from "export-from-json";

export default {
  data() {
    return {
      events: [],
      newId: "",
      event_name: "",
      event_description: "",
      status: "",
      showModal: false,
    };
  },
  async mounted() {
    this.loadEvents();
  },
  methods: {
    loadEvents() {
      fetch(`${process.env.VUE_APP_LIVE_COMMERCE_API}/events/`)
        .then((response) => response.json())
        .then((data) => (this.events = data));
    },

    create() {
      this.newId = uuidv4();

      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/`;
      const body = {
        event_id: this.newId,
        event_name: this.event_name,
        event_description: this.event_description,
      }
      axios
        .post(url, body)
        .then((res) => {
          console.log(res);
          this.showModal = false
          this.loadEvents
          this.$router.push('/event/'+this.newId)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancel() {
      this.newer = 0;
      this.newId = "";
      this.loadEvents();
    },

    downloadEvents() {
      const data = [{
        event_id: this.newId,
        event_name: this.event_name,
        event_description: this.event_description,
        event_date: this.event_date,
      }];
      const fileName = "Lista_Eventos";
      const exportType = exportXlsFile.types.xls;

      exportXlsFile({ data, fileName, exportType });
    },
  },
};
</script>

<style scoped>
.page {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 55px;
}

.container-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.sc-btn-red {
  background: #ab1414;
  color: white;
}

.sc-btn-red::after {
  background: #7d1702;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.the-modal :deep(.modal-wrapper) {
  display: inline-flex;
  vertical-align: middle;
}

.the-modal :deep(.modal-container) {
  width: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

</style>