<template>
  <div class="container background">
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/SC_Logo_White.png" />
      </div>
      <form @submit.prevent="signUp">
        <div class="form-input">
          <input
            v-model="email"
            type="email"
            placeholder="Email"
            autocomplete="username"
            required
          />
        </div>
        <div class="form-input">
          <input
            v-model="password"
            type="password"
            placeholder="Password"
            autocomplete="current-password"
            required
          />
        </div>
        <div class="form-input">
          <input
            v-model="passwordC"
            type="password"
            placeholder="Confirm Password"
            autocomplete="current-password"
            required
          />
        </div>
        <div class="form-error" v-if="error">
          {{ error }}
        </div>
        <div class="form-button">
          <button>Sign Up</button>
        </div>
      </form>
      <router-link class="toLogin" :to="'/login'">Already have an account?</router-link>
    </div>
  </div>
</template>

<script>
//import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordC:"",
      error: "",
    };
  },
  methods: {
    signUp(){

    }
  },
};
</script>
<style scoped>
  .background {
    background: url("@/assets/SC-Live-Commerce-Background.png");
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
  }


.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  width: 400px;
  height: 500px;
  background-color: #1975a6;
}

form {
  width: 100%;
}

input {
  width: 70%;
  margin: 5px;
}

.form-error {
  color: white;
  font-size: 10px;
  margin: 5px;
}

.logo img {
  width: 350px;
}

.toLogin{
  color: azure;
  margin-top: 10px;
}

</style>
