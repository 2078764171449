<template>
  <div class="page">
    <!-- Page Heading -->
    <div class="container-fluid">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Gestión del Canal</h1>
      </div>
      <div class="card mb-4">
        <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h3 class="m-0 font-weight-bold text-primary">
            {{ this.$route.params.channel }}
          </h3>
        </div>
        <div class="card-body">
          <div id="content-container">
            <h5>En Vivo</h5>
            <!-- FRAME DEL VIDEO -->
            <div class="stream">
              <div class="video-text">
                <p>Video Streaming</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.stream {
  background-color: black;
  width: 60%;
  height: 333px;
  margin-left: 220px;
}
.video-text {
  color: white;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}
.card {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  min-width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}
.page{
  margin-top: 78px;
}
</style>