<template>
  <div class="page">
    <div class="container-controls">
      <button class="sc-btn" @click="showModal = true">
        <span>Crear Cupones</span>
      </button>
      <button class="sc-btn" @click="download">
        <span>Descargar Guión</span>
      </button>
    </div>
    <div class="container-data">
      <!-- DataTales Example -->
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            Detalles del Producto
          </h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered" id="dataTable">
              <caption></caption>
              <thead>
                <tr>
                  <th>Nombre Producto</th>
                  <th>Precio Regular</th>
                  <th>Precio Oferta</th>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in products" :key="product">
                  <td>
                    {{ product.product_name }}
                  </td>
                  <td>
                    {{ product.product_regular_price }}
                  </td>
                  <td>{{ product.product_offer_price }}</td>
                  <td v-show="!product.product_description">
                    <span class="edit-span" @click="insert(product.content_id)"
                      >Insertar Descripción</span
                    >
                  </td>
                  <td v-show="product.product_description">
                    <span
                      class="edit-span"
                      @click="editDescription(product.content_id)"
                    >
                      {{ product.product_description }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="container-data">
      <!-- DataTales Example -->
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Cupones</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered" id="dataTable">
              <caption></caption>
              <thead>
                <tr>
                  <th>Cupón</th>
                  <th>Descripción</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cupon in cupons" :key="cupon">
                  <td>
                    {{ cupon.cupon_name }}
                  </td>
                  <td>
                    {{ cupon.cupon_description }}
                  </td>
                  <td>
                    <span class="edit-span" @click="deleteCupon(cupon.id)"
                      >Eliminar</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <the-modal :show="showModal" @close="showModal = false">
      <template #header>
        <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h6 class="m-0 font-weight-bold text-primary">
            Insertar Descripcion
          </h6>
        </div>
      </template>
      <template #body>
        <div class="card mb-4">
          <!-- Card Body -->
          <div class="card-body">
            <!-- Nombre del Contenido -->
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  >Nombre del Cupón</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                v-model="cupon_name"
                required
              />
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <h6>Descripcion</h6>
              </div>
              <textarea
                class="description"
                v-model="cupon_description"
                name="descripcion"
                maxlength="500"
                id="textarea"
                placeholder="Escribe aquí la descripcion"
              ></textarea>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="sc-btn" @click="insert_cupon">
          <span>Insertar Cupón</span>
        </button>
      </template>
    </the-modal>
    <the-modal :show="showModal2" @close="showModal2 = false">
      <template #header>
        <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h6 class="m-0 font-weight-bold text-primary">
            Insertar Descripcion
          </h6>
        </div>
      </template>
      <template #body>
        <div class="card mb-4">
          <!-- Card Body -->
          <div class="card-body">
            <!-- Nombre del Contenido -->
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <h6>Descripción del Producto</h6>
              </div>
              <textarea
                class="description"
                v-model="product_description"
                name="descripcion"
                maxlength="500"
                id="textarea"
                placeholder="Escribe aquí la descripcion"
              ></textarea>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button class="sc-btn" @click="insert_description">
          <span>Insertar Descripción</span>
        </button>
      </template>
    </the-modal>
  </div>
</template>
<script>
import axios from "axios";
import { jsPDF } from "jspdf";

export default {
  data() {
    return {
      products: [],
      cupons: [],
      num: "",
      showModal: false,
      showModal2: false,
      product_description: "",
      cupon_description: "",
      cupon_name: "",
      description: "",
    };
  },

  mounted() {
    this.loadProduct();
    this.loadCupons();
  },

  methods: {
    loadProduct() {
      fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/products/${this.$route.params.event_id}`
      )
        .then((response) => response.json())
        .then((data) => (this.products = data));
    },

    loadCupons() {
      fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/cupons/${this.$route.params.event_id}`
      )
        .then((response) => response.json())
        .then((data) => (this.cupons = data));
    },

    insert_cupon() {
      const body = {
        cupon_name: this.cupon_name,
        cupon_description: this.cupon_description,
      };
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/cupon/${this.$route.params.event_id}`;
      axios
        .post(url, body)
        .then((res) => {
          console.log(res);
          this.cupon_name = "";
          this.cupon_description = "";
          this.showModal = false;
          this.loadCupons();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    insert_description() {
      const body = {
        product_description: this.product_description,
        content_id: this.description.content_id,
      };
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/description/`;
      axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          this.showModal2 = false;
          this.product_description = "";
          this.loadProduct();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    insert(content_id) {
      fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/description/${content_id}`
      )
        .then((response) => response.json())
        .then((data) => (this.description = data));
      this.product_description = "";
      this.showModal2 = true;
    },

    async editDescription(content_id) {
      await fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/description/${content_id}`
      )
        .then((response) => response.json())
        .then((data) => (this.description = data));

      this.product_description = this.description.product_description;
      this.showModal2 = true;
    },

    deleteCupon(id) {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/cupon/${id}`;
      axios
        .delete(url)
        .then((res) => {
          console.log(res);
          this.loadCupons();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    download() {
      const doc = new jsPDF({
        orientation: "landscape",
      });

      var text = "CUPONES";

      const logoLC = require("@/assets/logoLC.png");
      var img1 = new Image();
      img1.src = logoLC;

      const logoSC = require("@/assets/logoSC.png");
      var img2 = new Image();
      img2.src = logoSC;

      doc.addImage(img1, "png", 10, 10, 20.33, 20.33);
      doc.addImage(img2, "png", 250, 10, 40.16, 10.33);
      var splitText = "";

      doc.setFont("arial", "bold").setFontSize(37).text(text, 120, 30);

      for (var i = 0; i < this.cupons.length; i++) {
        splitText = doc
          .setFont("Arial")
          .setFontSize(37)
          .splitTextToSize(
            this.cupons[i].cupon_description + " " + this.cupons[i].cupon_name,
            285
          );

        if (i == 0) {
          doc.setFont("arial", "").setFontSize(37).text(splitText, 10, 55);
        } else {
          doc
            .addPage()
            .setFont("arial", "")
            .setFontSize(37)
            .text(splitText, 10, 45);
          doc.addImage(img1, "png", 10, 10, 20.33, 20.33);
          doc.addImage(img2, "png", 250, 10, 40.16, 10.33);
        }
      }

      for (var j = 0; j < this.products.length; j++) {
        text = doc
          .setFont("Arial")
          .setFontSize(37)
          .splitTextToSize(this.products[j].product_name, 200);
        splitText = doc
          .setFont("Arial")
          .setFontSize(37)
          .splitTextToSize(this.products[j].product_description, 270);
        console.log(doc.getFontList());
        doc
          .addPage()
          .setFontSize(33)
          .setFont("arial", "bold")
          .text(text, 50, 15);
        doc.setFont("arial", "").setFontSize(30).text(splitText, 10, 55);
        doc.addImage(img1, "png", 10, 10, 20.33, 20.33);
        doc.addImage(img2, "png", 250, 10, 40.16, 10.33);
        doc
          .setFont("arial", "bold")
          .setFontSize(49)
          .text(
            this.products[j].product_regular_price +
              " => " +
              this.products[j].product_offer_price,
            50,
            200
          );
      }

      doc.save("guion.pdf");
    },
  },
};
</script>


<style scoped>
.page {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 70px;
}

.container-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.the-modal :deep(.modal-wrapper) {
  display: inline-flex;
  vertical-align: middle;
}

.the-modal :deep(.modal-container) {
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.description {
  position: relative;
  height: 200px;
  border-radius: 20px;
  width: 400px;
  display: flex;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: scroll;
  resize: none;
}
.edit-span {
  cursor: pointer;
}
</style>