<template>
  <div class="body">
    <nav>
      <ul
        class="navbar-nav bg-gradient-sportscam sidebar"
      >
        <div class="container-brand">
          <router-link :to="'/'">
          <img height="60px" src="@/assets/SC_Logo_White.png" />
          </router-link>
        </div>

        <hr class="sidebar-divider" />

        <div class="sidebar-heading">LIVE COMMERCE</div>

        <li class="nav-item active">
          <div class="nav-link">
            <font-awesome-icon icon="fa-solid fa-tachometer-alt" />
            <router-link :to="'/dashboard'">Dashboard</router-link>
          </div>
        </li>

        <li class="nav-item active">
          <div class="nav-link">
            <font-awesome-icon icon="fa-solid fa-tv" />
            <router-link :to="'/channels'">Canales</router-link>
          </div>
        </li>

        <li class="nav-item active">
          <div class="nav-link">
            <font-awesome-icon icon="fa-solid fa-calendar" />
            <router-link :to="'/events'">Transmisiones</router-link>
          </div>
        </li>

        <hr class="sidebar-divider" />

        <div class="sidebar-heading">ADMIN</div>

        <li class="nav-item active">
          <div class="nav-link">
            <font-awesome-icon icon="fa-solid fa-user" />
            <router-link :to="'/users'">Usuarios</router-link>
          </div>
        </li>

        <li class="nav-item active">
          <div class="nav-link">
            <font-awesome-icon icon="fa-solid fa-gear" />
            <router-link :to="'/config'">Parámetros</router-link>
          </div>
        </li>

        <hr class="sidebar-divider" />

      </ul>
    </nav>
  </div>
</template>

<style scoped>
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.sidebar {
  width: 18rem !important;
  height: 100vh;
  white-space: nowrap;
  position: fixed;
}

.container-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem
}

.nav-link {
    display: flex;
    gap: 10px;
    align-items: center;
    color: white;
}

.nav-link svg {
    width: 20px;
}

.nav-link a {
    color: white;
    text-decoration: none; /* no underline */
}

.nav-link a:hover {
    text-decoration: underline;
}

.bg-gradient-sportscam {
  background-color: #1975a6;
  background-image: linear-gradient(180deg, #1975a6 10%, #092a3c 100%);
  background-size: cover;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

img {
  vertical-align: middle;
  border-style: none;
  height: 60px;
}

hr.sidebar-divider {
  margin: 0 1rem 1rem;
}

.nav-item.active {
  font-weight: 700;
}

.nav-item {
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  width: 14rem;
}

.sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
  text-align: left;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}



</style>