import { createApp } from 'vue'
import App from './App.vue'
import router from "./router.js";
import io from 'socket.io-client';

import SideBar from './components/SideBar.vue';
import TopBar from './components/TopBar.vue';
import TheModal from './components/TheModal.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faTachometerAlt, faTv, faCalendar, faGear, faUser} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret, faTachometerAlt, faTv, faCalendar, faGear, faUser)

const app = createApp(App)
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('side-bar', SideBar)
app.component('top-bar', TopBar)
app.component('the-modal', TheModal)

app.config.globalProperties.socket = io('https://wss.sportscam.pe');

app.mount('#app')