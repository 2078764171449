<template>
  <div class="page">
    <div class="card">
      <h3>Administración de Usuarios</h3>
      <table class="table">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id" v-show="user.status !== '9'">
            <td>{{ user.email }}</td>
            <td>{{ user.name  }}</td>
            <td>
              {{ user.status === "1" ? "Activo" : "Inactivo" }}
              <input
                type="checkbox"
                :checked="user.status === '1'"
                @change="handleChange(user.id, $event)"
              />
            </td>
            <td>
              <span class="edit-span" @click="loadUserData(user)"
                >Editar</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="create-btn">
      <button class="sc-btn" @click="showCreateModal = true">
        <span>Crear Usuario</span>
      </button>
    </div>
      <the-modal
        :show="showCreateModal"
        title="Crear Usuario"
        @close="showCreateModal = false"
      >
        <template #body>
          Nombre: <input type="text" v-model="newUser.name" required />
          Email: <input type="text" v-model="newUser.email" required />
          Password: <input type="password" v-model="newUser.password" required />
        </template>
        <template #footer>
          <!--
            <button class="sc-btn" @click="update(userSelected.id)">
              <span>Guardar</span>
            </button>
            <button class="sc-btn btn-red" @click="erase(userSelected.id)">
              <span>Borrar</span>
            </button>
          -->
          <button class="sc-btn" @click="createUser"><span>Crear</span></button>
        </template>
      </the-modal>
      <the-modal
        :show="showEditModal"
        title="Editar Usuario"
        @close="showEditModal = false"
      >
        <template #body>
          Nombre: <input type="text" v-model="name" required />
          Email: <input type="text" v-model="email" readonly disabled required />

        </template>
        <template #footer>
          <!--
            <button class="sc-btn" @click="update(userSelected.id)">
              <span>Guardar</span>
            </button>
            <button class="sc-btn btn-red" @click="erase(userSelected.id)">
              <span>Borrar</span>
            </button>
          -->
          <button class="sc-btn" @click="update(userSelected.id)"><span>Guardar</span></button>
        </template>
      </the-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      userSelected: "",
      showEditModal: false,
      showCreateModal: false,
      status: "",
      name: "",
      email: "",
      newUser: {},
    };
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      fetch(`${process.env.VUE_APP_LIVE_COMMERCE_API}/users/`)
        .then((response) => response.json())
        .then((data) => (this.users = data));
    },

    loadUserData(user) {

      fetch(`${process.env.VUE_APP_LIVE_COMMERCE_API}/users/${user.id}`)
        .then((response) => response.json())
        .then((data) => (this.userSelected = data));
      
      this.email = user.email;
      this.name = user.name;
      this.showEditModal = true;
    },

    handleChange(user_id, event) {
      console.log("Change id", user_id);
      console.log("event", event);
      console.log("check status?", event.target.checked);

      if (event.target.checked) {
        this.updateUserStatus(user_id, '1');
      } else {
        this.updateUserStatus(user_id, '0');
      }
    },

    updateUserStatus(user_id, status) {
      const body = {
        status: status,
      };
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/users/${user_id}`;

      axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          this.loadUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    erase(userID) {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/users/${userID}`;
      axios
        .delete(url)
        .then((res) => {
          console.log(res);
          this.showModal = false;
          this.userSelected = "";
          this.loadUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createUser() {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/users/`;

      const body = this.newUser;

      axios
        .post(url, body)
        .then((res) => {
          console.log(res);
          this.showCreateModal = false;
          // Clear inputs
          this.newUser = {};
          this.loadUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    update(custid){
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/users/`;
      const body = {
        id : custid,
        name: this.name
      }

      axios
       .put(url, body)
       .then((res) => {
          console.log(res);
          this.showEditModal = false;
          this.loadUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.page {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 55px;
}
</style>