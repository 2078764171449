<template>
  <div class="page">
    <!-- MODAL -->
    <div class="the-modal">
      <the-modal :show="showModal" @close="showModal = false">
        <template #header>
          <!-- Card Header - Dropdown -->
          <div
            class="
              card-header
              py-3
              d-flex
              flex-row
              align-items-center
              justify-content-between
            "
          >
            <h6 class="m-0 font-weight-bold text-primary">Crear Contenido</h6>
          </div>
        </template>
        <template #body>
          <div class="col-xl-6 col-lg-7 col-1">
            <div class="card mb-4">
              <!-- Card Body -->
              <div class="card-body">
                <!-- Id del Contenido -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >ID del Contenido</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="contentId"
                    required
                  />
                </div>
                <!-- Nombre del Contenido -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Nombre del Contenido</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="content_name"
                    required
                  />
                </div>
                <!-- Nombre del Producto -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Nombre del Producto</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_name"
                    required
                  />
                </div>
                <!-- Tipo de Contenido -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Tipo de Contenido</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    v-model="content_type"
                    required
                  >
                    <option selected="" value="">Seleccionar...</option>
                    <option value="Product_Card">Tarjeta de Producto</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
                <!-- IMAGE URL 1 -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >PRODUCT IMAGES (MAX 3)</span
                    >
                  </div>
                  <input
                    type="file"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    ref="inputFile1"
                    @change="uploadFile($event, 3)"
                    multiple
                    accept="image/*"
                    required
                  />
                </div>
                <!-- IMAGE URL 2
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >URL IMAGE 2</span
                    >
                  </div>
                  <input
                    type="file"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    ref="inputFile2"
                    @change="uploadFile($event, 1)"
                    required
                  />
                </div>
                IMAGE URL 3
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >URL IMAGE 3</span
                    >
                  </div>
                  <input
                    type="file"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    ref="inputFile3"
                    @change="uploadFile($event, 2)"
                    required
                  />
                </div> -->
              </div>
            </div>
            <div class="card mb-4">
              <!-- Card Body -->
              <div class="card-body">
                <!-- Regular Price -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Precio Regular</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_regular_price"
                    required
                  />
                </div>
                <!-- Offer Price -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Precio Oferta</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_offer_price"
                    required
                  />
                </div>
                <!-- Discount -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Descuento</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_discount_percentage"
                    required
                  />
                </div>
                <!-- Link Product -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Link Producto</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_link"
                    required
                  />
                </div>
                <!-- CTA BOTON -->
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      >Producto CTA Boton</span
                    >
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    v-model="product_cta_button"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div>{{ error }}</div>
          <button class="btn btn-red ml-2" @click="cancel">Cancelar</button>
          <button
            class="btn btn-success ml-2"
            @click="create"
            v-show="!product.content_id"
          >
            Crear
          </button>
          <button
            class="btn btn-primary ml-2"
            @click="edit"
            v-show="product.content_id"
          >
            Guardar
          </button>
        </template>
      </the-modal>
    </div>
    <div class="the-modal">
      <the-modal :show="showModal2" @close="showModal2 = false">
        <template #header>
          <div class="card mb-4">
            <div
              class="
                card-header
                py-3
                d-flex
                flex-row
                align-items-center
                justify-content-between
              "
            >
              <h6 class="m-0 font-weight-bold text-primary">Conectar OBS</h6>
            </div>
          </div>
        </template>
        <template #body>
          <div class="card-body">
            <!-- CONFIGURACION OBS -->
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  >IP ADDRESS</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                v-model="ip"
                required
              />
            </div>
            <!-- <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text"
                        id="inputGroup-sizing-default"
                        >PORT OBS</span
                      >
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      v-model="port"
                      required
                    />
                  </div> -->
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-default"
                  >PASSWORD OBS</span
                >
              </div>
              <input
                type="password"
                class="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                v-model="password"
                required
              />
            </div>
          </div>
        </template>
        <template #footer>
          <div>{{ conn_status }}</div>
          <button class="btn btn-red ml-2" @click="showModal2 = false">
            Cancel
          </button>
          <button class="btn btn-primary ml-2" @click="connect">Connect</button>
        </template>
      </the-modal>
    </div>
    <div class="container-fluid">
      <!-- Page Heading -->
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">Gestión de Contenido</h1>
      </div>

      <!-- Content Row -->
      <div class="row">
        <div class="col-xl-6 col-lg-7 col-2">
          <div class="frames">
            <iframe
              name="f9e562a23f32a8"
              data-testid="fb:video Facebook Social Plugin"
              title="fb:video Facebook Social Plugin"
              frameborder="0"
              allowtransparency="true"
              allowfullscreen="true"
              scrolling="no"
              allow="encrypted-media"
              src="https://www.facebook.com/v3.2/plugins/video.php?allowfullscreen=false&amp;app_id=&amp;autoplay=true&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df3460ba51a2142c%26domain%3Dlivecommerce.sportscam.pe%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flivecommerce.sportscam.pe%252Ff269914dde131ac%26relation%3Dparent.parent&amp;container_width=716&amp;href=https%3A%2F%2Fwww.facebook.com%2FLaCuracaoPeru%2Fvideos%2F565189951825298%2F&amp;locale=es_LA&amp;sdk=joey&amp;show_text=false"
              style="
                border: none;
                visibility: visible;
                width: 320px;
                height: 200px;
              "
              class=""
            ></iframe>
            <iframe
              src="https://livecommerce.sportscam.pe/lacuracao/live1/banner/"
              title="description"
              height="425"
            ></iframe>
          </div>
          <div class="card mb-4">
            <div
              class="
                card-header
                py-3
                d-flex
                flex-row
                align-items-center
                justify-content-between
              "
            >
              <h6 class="m-0 font-weight-bold text-primary">
                Lista de Contenido
              </h6>
              <div class="to-modal-btn">
                <button
                  class="btn btn-primary ml-2"
                  @click="(showModal = true), (action = 'Crear contenido')"
                >
                  Crear
                </button>
                <button
                  class="btn btn-dark ml-2"
                  @click="(showModal2 = true), (action = 'Crear contenido')"
                  v-show="showConnect"
                >
                  Connect OBS
                </button>
                <button
                  class="btn btn-red ml-2"
                  @click="disconnect"
                  v-show="!showConnect"
                >
                  Disconnect OBS
                </button>
              </div>
            </div>
            <div class="card-body c-grid-1">
              <div id="content-container">
                <div class="c-grid-2">
                  <div class="card w-85">
                    <h5 class="card-title">Limpiar</h5>
                    <p class="card-text">Escena</p>
                    <button class="btn btn-dark ml-2" @click="cleanScene">
                      Mostrar Contenido OBS
                    </button>
                  </div>
                  <div class="card w-85">
                    <h5 class="card-title">Cupones</h5>
                    <p class="card-text">Escena</p>
                    <button class="btn btn-dark ml-2" @click="cuponScene">
                      Mostrar Contenido OBS
                    </button>
                  </div>
                  <div
                    class="card w-85"
                    v-for="element in elements"
                    :key="element"
                  >
                    <h5 class="card-title">{{ element.content_name }}</h5>
                    <p class="card-text">{{ element.product_name }}</p>
                    <button
                      class="btn btn-dark ml-2"
                      @click="showContent(element, element.content_id)"
                    >
                      Mostrar Contenido
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click="getEditContent(element.content_id)"
                    >
                      Editar
                    </button>
                    <button
                      class="btn btn-red ml-2"
                      @click="
                        deleteContent(element.content_id, element.content_name)
                      "
                    >
                      Borrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OBSWebSocket from "obs-websocket-js";

const obs = new OBSWebSocket();
var selectedFile = new Array(3);
const fs = new FormData();

export default {
  data() {
    return {
      elements: [],
      content: "",
      content_id: "",
      contentId: "",
      product: {},
      content_type: "Product_Card",
      product_name: "",
      content_name: "",
      product_regular_price: "",
      product_offer_price: "",
      product_discount_percentage: "",
      product_link: "",
      product_cta_button: "VER PRODUCTO",
      error: "",
      ip: "",
      password: "",
      conn_status: "",
      selectedFile: "",
      order: 0,
      action: "",
      showModal: false,
      showModal2: false,
      showConnect: true,
    };
  },

  mounted() {
    this.loadContent();
  },

  methods: {
    loadContent() {
      fetch(
        `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/content`
      )
        .then((response) => response.json())
        .then((data) => (this.elements = data));
    },

    connect() {
      obs
        .connect({
          address: this.ip, //+ ":" + this.port,
          password: this.password, // Change Password
          secure: true,
        })
        .then(() => {
          console.log(`Success! We're connected & authenticated.`);
          this.conn_status = "Success!";
          this.showModal2 = false;
          this.showConnect = false;
          console.log(obs.send("GetSceneList"));
          return obs.send("GetSceneList");
        })
        .catch((err) => {
          this.conn_status = "Connection Failed!";
          console.error(err);
        });
    },

    showContent(element, content_id) {
      console.log(
        JSON.stringify({
          content_id: content_id,
          content_name: element.content_name,
        })
      );
      const body = { content_id: content_id };
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/content/current`;

      axios
        .post(url, body, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          console.log(res);
          this.socket.emit("show-content", element);
          obs.send("SetCurrentScene", {
            "scene-name": element.content_name,
          });
        });
    },

    uploadFile(event, counter) {
      if (event.target.files.length > 3) {
        alert("Please select a maximum of 3 files.");
        this.$refs.inputFile1.value = null;
      } else {
        for (var i = 0; i < counter; i++) {
          selectedFile[i] = event.target.files[i];
        }
        console.log(selectedFile);
      }
    },

    async edit() {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/events/${this.$route.params.event_id}/content/${this.product.content_id}`;
      const body = {
        product_name: this.product_name,
        content_name: this.content_name,
        product_regular_price: this.product_regular_price,
        product_offer_price: this.product_offer_price,
        product_discount_percentage: this.product_discount_percentage,
        product_link: this.product_link,
        product_cta_button: this.product_cta_button,
      };
      await axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          this.content_type = "";
          this.product_name = "";
          this.content_name = "";
          this.product_regular_price = "";
          this.product_offer_price = "";
          this.product_discount_percentage = "";
          this.product_link = "";
          this.product = {};
          this.error = "";
          this.showModal = false;
          this.loadContent();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(selectedFile)
      if (selectedFile !== '') {
        for (var i = 0; i < selectedFile.length; i++) {
          fs.append("myFile", selectedFile[i], selectedFile[i].name);

          const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/fileUpload/${
            this.$route.params.event_id
          }/${this.content_id}/${i + 1}`;
          axios.post(url, fs).then((res) => {
            console.log(res.data.url);
          });
          fs.delete("myFile");
        }
        this.content_id = "";
        this.selectedFile = "";
        this.$refs.inputFile1.value = null;
      }
    },

    deleteContent(contentId, name) {
      var confirmation = window.confirm(
        "Estas seguro de eliminar el contenido " + name + "?"
      );

      if (confirmation === true) {
        const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/content/${contentId}`;

        axios
          .delete(url)
          .then((res) => {
            console.log(res);
            window.alert(name + " Eliminado");
            this.loadContent();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        window.alert("Operación Cancelada");
      }
    },

    async create() {
      var contentType = this.content_type;

      if (
        contentType !== "" &&
        this.product_name !== "" &&
        this.content_name !== "" &&
        this.product_regular_price !== "" &&
        this.product_offer_price !== "" &&
        this.product_discount_percentage !== "" &&
        this.product_link !== "" &&
        this.product_cta_button !== ""
      ) {
        const body = {
          content_id: this.contentId,
          event_id: this.$route.params.event_id,
          content_type: this.content_type,
          product_name: this.product_name,
          content_name: this.content_name,
          product_regular_price: this.product_regular_price,
          product_offer_price: this.product_offer_price,
          product_discount_percentage: this.product_discount_percentage,
          product_link: this.product_link,
          product_cta_button: this.product_cta_button,
        };
        const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/content/`;

        await axios
          .post(url, body)
          .then((res) => {
            console.log(res);
            this.contentId = "";
            this.content_type = "Product_Card";
            this.product_name = "";
            this.content_name = "";
            this.product_regular_price = "";
            this.product_offer_price = "";
            this.product_discount_percentage = "";
            this.product_link = "";
            this.error = "";
            this.content_id = res.data.content_id;
            this.loadContent();
          })
          .catch((error) => {
            console.log(error);
            this.error = "ID ya existe!";
          });

        for (var i = 0; i < selectedFile.length; i++) {
          fs.append("myFile", selectedFile[i], selectedFile[i].name);

          const url2 = `${process.env.VUE_APP_LIVE_COMMERCE_API}/fileUpload/${
            this.$route.params.event_id
          }/${this.content_id}/${i + 1}`;
          
          axios.post(url2, fs).then((res) => {
            console.log(res.data.url);
          });
          fs.delete("myFile");
        }
        this.content_id = "";
        this.selectedFile = "";
        this.$refs.inputFile1.value = null;
      } else {
        this.error = "faltan datos";
      }
    },

    getEditContent(contentId) {
      const url = `${process.env.VUE_APP_LIVE_COMMERCE_API}/content/${contentId}`;
      axios
        .get(url)
        .then((res) => {
          this.product = res.data;
          this.contentId = this.product.content_id
          this.content_id = this.product.content_id;
          this.content_type = this.product.content_type;
          this.product_name = this.product.product_name;
          this.content_name = this.product.content_name;
          this.product_regular_price = this.product.product_regular_price
            .slice(3)
            .replace(",", "");
          this.product_offer_price = this.product.product_offer_price
            .slice(3)
            .replace(",", "");
          this.product_discount_percentage =
            this.product.product_discount_percentage;
          this.product_link = this.product.product_link;
          this.product_cta_button = this.product.product_cta_button;
          this.showModal = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cleanScene() {
      obs.send("SetCurrentScene", {
        "scene-name": "Limpio",
      });
    },
    cuponScene() {
      obs.send("SetCurrentScene", {
        "scene-name": "Cupon",
      });
    },

    cancel() {
      this.contentId = "";
      this.product = "";
      this.content_type = "Product_Card";
      this.product_name = "";
      this.content_name = "";
      this.product_regular_price = "";
      this.product_offer_price = "";
      this.product_discount_percentage = "";
      this.product_link = "";
      this.loadContent();
      this.showModal = false;
    },
  },

  async disconnect() {
    await obs.disconnect();
    this.showConnect = true;
  },
};
</script>

<style scoped>

.page{
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;
  margin-top: 25px;
}
.card-header:first-child {
  border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}

div {
  display: block;
}

.card {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.card-title {
  margin-bottom: 0.75rem;
}
h5 {
  font-size: 1.25rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
h5 {
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}
h5 {
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button {
  -webkit-appearance: button;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}

.col-xl-6 {
  display: flex;
  flex: 0 0 50%;
  max-width: 100%;
}

.col-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.col-2 {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  gap: 20px;
}

/* .c-grid-1{
  display: grid;
  grid-template-columns: 2fr;
  width:60%
} */

.c-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

.row {
  display: grid;
  grid-template-rows: 2fr;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

iframe {
  align-self: unset;
}

.the-modal :deep(.modal-wrapper) {
  display: inline-flex;
  vertical-align: middle;
}

.the-modal :deep(.modal-container) {
  width: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.to-modal-btn {
  padding: auto;
}

.frames {
  display: flex;
  flex-direction: column;
}
</style>