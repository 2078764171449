import { createRouter, createWebHistory } from "vue-router";
import ContentPage from "./pages/ContentPage.vue";
import LoginPage from "./pages/LoginPage.vue";
import EventsPage from "./pages/EventsPage.vue";
import UsersPage from "./pages/UsersPage.vue";
import EventPage from "./pages/EventPage.vue";
import SignUpPage from "./pages/SignUpPage.vue";
import UploadImages from "./pages/UploadImages.vue"
import ConfigPage from "./pages/ConfigPage.vue"
import DashboardPage from "./pages/DashboardPage.vue"
import ChannelsPage from "./pages/ChannelsPage.vue"
import ChannelPage from "./pages/ChannelPage.vue"
import ScriptPage from "./pages/ScriptPage.vue"


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    { path: "/home", redirect: "/dashboard" },
    { path: "/channels", component: ChannelsPage, meta: {requiresAuth: true, hideBars:false} },
    { path: "/channels/:channel", component: ChannelPage, meta: {requiresAuth: true, hideBars:false}},
    { path: "/dashboard", component: DashboardPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/login", component: LoginPage, meta: { requiresUnauth: true, hideBars: true}},
    { path: "/content/:event_id", component: ContentPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/events", component: EventsPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/users", component: UsersPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/config", component: ConfigPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/event/:event_id", component: EventPage, meta: { requiresAuth: true, hideBars: false} },
    { path: "/registration", component: SignUpPage, meta: { requiresUnauth: true, hideBars: true} },
    { path: "/content/:event_id/:content_id", component: UploadImages, meta: { requiresAuth: true, hideBars: false} },
    { path: "/script/:event_id", component: ScriptPage, meta: {requiresAuth: true, hideBars: false}},
    { path: '/:notFound(.*)', redirect: "/" }
  ],
});

router.beforeEach(function(to, _, next) {
  if (to.meta.requiresAuth && !sessionStorage.getItem("FirebaseToken")) {
    next('/login');
  } else if (to.meta.requiresUnauth && sessionStorage.getItem("FirebaseToken")) {
    next('/home');
  } else {
    next();
  }
});

export default router;
